<template>
  <div style="overflow: auto; width:100%;">
         
	<vue-good-table
    @on-selected-rows-change="selectionChanged"
    :columns="columns"
    :rows="eventAssignments"
    styleClass="vgt-table bordered condensed"
    :pagination-options="{
      enabled: true,
      mode: 'records',
      perPage: 50,
    }"
    :select-options="{
      enabled: true,
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      selectOnCheckboxOnly: true,
    }"
    :search-options="{
      enabled: false,
      placeholder: 'Search this table',
    }"
    :sort-options="{
      enabled: true,
      multipleColumns: true,
      initialSortBy: [
        {field: 'firstName', type: 'asc'},
        {field: 'date', type: 'desc'},
      ],
    }"
  >
  	<div slot="selected-row-actions">

      <button class="btn btn__small btn__dark mr-4" @click="exportReport()">
        Export <i class="fa-regular fa-arrow-up-right-from-square ml-2"></i>
        <transition name="fade">
          <span class="ml-2" v-if="performingRequest2">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
      </button>

    </div>
   
    <template slot="table-row" slot-scope="props">

      <span v-if="props.column.field == 'delete'" class="flex flex-row">
        <button :disabled="props.row.isTimesheetSubmitted" v-if="!props.row.hidden" class="btn btn__danger btn__small ml-2 mr-2" @click="deleteEntry(props.row)">
          <i class="fa-regular fa-arrows-rotate fa-spin" v-if="props.row.deleting"></i>
          <i class="fa-solid fa-trash" v-else></i>
        </button>
      </span>        


      <span v-else-if="props.column.field == 'regRate'">
        <input type="number" 
          v-model.trim="props.row.regRate" 
          :id="props.row.id + `regRate`" 
          :readonly="props.row.removed" 
          :class="{ muted: props.row.removed }" 
          @change="updateAssignment(props.row)"
          />
          
      </span>
     
      <span v-else-if="props.column.field == 'firstName'">
        <input type="text" :id="props.row.id + props.row.firstName" v-model.trim="props.row.firstName" readonly :class="{ muted: props.row.removed }" />
      </span>
      <span v-else-if="props.column.field == 'lastName'">
        <input type="text" :id="props.row.id + props.row.lastName" v-model.trim="props.row.lastName" readonly :class="{ muted: props.row.removed }" />
      </span>

      <span v-else-if="props.column.field == 'inTime'" style="display: flex;">
        <input type="time" :id="props.row.id + `inTime`" v-model.trim="props.row.inTime" @blur="updateAssignment(props.row)" :class="{ muted: props.row.removed }" />
        <button  v-if="props.row.inTime" class="btn btn__icon ml-3" @click="removeIn(props.row)"><i class="fa-solid fa-xmark danger ml-2 mr-2"></i></button>
      </span>

      <span v-else-if="props.column.field == 'outTime'" style="display: flex;">
        <input  :id="props.row.id + `outTime`" type="time" v-model.trim="props.row.outTime" @blur="updateAssignment(props.row)" :class="{ muted: props.row.removed }" />
        <button v-if="props.row.outTime" class="btn btn__icon ml-3" @click="removeOut(props.row)"><i class="fa-solid fa-xmark danger ml-2 mr-2"></i></button>
      </span>

      <span v-else-if="props.column.field == 'date'">
        <span v-if="props.row.date" :class="{ muted: props.row.removed }">{{props.row.date | moment("MM/DD/YYYY")}}</span>
        <!-- <span v-if="props.row.date && isPaidAssignments && props.row.returned && props.row.returned.effectiveDate">{{props.row.returned.effectiveDate}}</span> -->
      </span>

      <span v-else-if="props.column.field == 'day'">
        <span v-if="props.row.date" :class="{ muted: props.row.removed }">{{props.row.date| moment("dddd")}}</span>
        <!-- <span v-if="props.row.date && isPaidAssignments && props.row.returned && props.row.returned.effectiveDate">{{props.row.returned.effectiveDate}}</span> -->
      </span>

      <span v-else-if="props.column.field == 'position'">
        <v-select
          label="title" 
          :options="props.row.eventInfo.jobs"
          v-model="props.row.position"
          @input="updateAssignment(props.row)"
          :id="props.row.id + `job`"
          >
        </v-select>
      </span>

      <span v-else-if="props.column.field == 'breakTime'">
        <input class="readable" type="number" v-model.trim="props.row.breakTime" :id="props.row.id + `breakTime`" @blur="updateAssignment(props.row)" :class="{ muted: props.row.removed }" />
      </span>
<!-- 
      <span v-else-if="props.column.field == 'totalHours'">
        <input class="readable" :readonly="isPaidAssignments || props.row.totalUpdating" type="number" v-model.lazy.trim="props.row.totalHours" :id="props.row.id + `totalHours`" @change="onEditTotalHours(props.row)" :class="{ muted: props.row.removed }" />
      </span>

      <span v-else-if="props.column.field == 'regHours'">
        <input class="readable" :readonly="isPaidAssignments || props.row.totalUpdating" type="number" v-model.lazy.trim="props.row.regHours" :id="props.row.id + `regHours`" @change="onManEditRegHours(props.row)" :class="{ muted: props.row.removed }"/>
      </span>
      <span v-else-if="props.column.field == 'minHours'">
        <input class="readable" :readonly="isPaidAssignments || props.row.totalUpdating" type="number" v-model.trim="props.row.minHours" :id="props.row.id + `minRate`" @blur="onEditMinHours(props.row)" :class="{ muted: props.row.removed }" />
      </span>
      <span v-else-if="props.column.field == 'otHours'">
        <input class="readable" :readonly="isPaidAssignments || props.row.totalUpdating" type="number" v-model.trim="props.row.otHours" :id="props.row.id + `otHours`" @blur="onEditotHours(props.row)" :class="{ muted: props.row.removed }"/>
      </span>
      <span v-else-if="props.column.field == 'ot2Hours'">
        <input class="readable" :readonly="isPaidAssignments || props.row.totalUpdating" type="number" v-model.trim="props.row.ot2Hours" :id="props.row.id + `ot2Hours`" @blur="onEditot2Hours(props.row)" :class="{ muted: props.row.removed }"/>
      </span>
      <span v-else-if="props.column.field == 'mbp'">
        <input class="readable" :readonly="isPaidAssignments || props.row.totalUpdating" type="number" v-model.trim="props.row.mbp" :id="props.row.id + `mbp`" @blur="onEditMBP(props.row)" :class="{ muted: props.row.removed }"/>
      </span>
      <span v-else-if="props.column.field == 'tips'">
        <input class="readable" :readonly="isPaidAssignments || props.row.totalUpdating" type="number" v-model.trim="props.row.tips" :id="props.row.id + `tips`" @blur="onEditTips(props.row)" :class="{ muted: props.row.removed }" />
      </span>


      <span v-else-if="props.column.field == 'status'" :class="{ muted: props.row.removed }" style="padding:0; margin:0;">
        <div v-if="type && type == 'Contractors'" style="width:152px;">
          <v-select
            :id="props.row.id + `status`"
            label="status" 
            :options="statuses"
            v-model="props.row.status"
            @input="onUpdateStatus(props.row)"
            :readonly="isPaidAssignments || props.row.totalUpdating"
            :clearable=true
            >
          </v-select>
        </div>
      </span>
      <span v-else-if="props.column.field == 'paystatus'" :class="{ muted: props.row.removed }">
        <v-select
          :id="props.row.id + `payStatus`"
          label="status" 
          :options="paystatuses"
          v-model="props.row.paystatus"
          @input="onUpdatePay(props.row)"
          :readonly="isPaidAssignments || props.row.totalUpdating"
          :clearable=true
          >
        </v-select>
      </span> -->
      


      


      
      <span v-else-if="props.column.field == 'link' && props.row && props.row.userId" :class="{ muted: props.row.removed }">
      <router-link :to="`/users/` + props.row.userId" target="_blank">
        <i class="fas fa-external-link ml-3 mr-3"></i>
      </router-link>
    </span>

      


      <span v-else-if="props.column.field == 'submit'">
        <button :disabled="props.row.isTimesheetSubmitting" v-if="!props.row.isTimesheetSubmitted" class="btn btn__primary btn__small ml-2 mr-2" @click.once="sendTimesheetToEveree(props.row)">
            Submit
          </button>
        <button v-if="props.row.isTimesheetSubmitted" class="btn btn__outlined btn__small ml-2 mr-2">
            Sent<i class="fa-solid fa-check ml-2"></i>

            </button>

      </span>


       <span v-else :class="{ muted: props.row.removed }">
        {{props.formattedRow[props.column.field]}}
      </span>

    </template>
  </vue-good-table>
  </div>
</template>

<style scoped>
  .vgt-selection-info-row {
    height: 3.28rem;
  }
  input.readable:read-only {
    background-color: #eee !important;
  }
</style>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import TimesheetNote from '@/components/Timesheets/TimesheetNote.vue'
import ExportService from "@/services/ExportService"
import firebase from 'firebase/app';
import * as moment from 'moment'
const fb = require('../firebaseConfig.js')

export default {
	props: ['eventAssignments', 'type', 'eventInfo'],
  data: () => ({
    performingRequest: false,
    performingRequest2: false,
    performingRequest3: false,
    activeItem: null,
    isProcessing: false,
    activeDay: '',
    batch: [],
    statuses: ['completed', 'no-show', 'dropped', 'arrived late', 'left early', 'client fired', 'terminated' ],
    paystatuses: ['paid', 'pending', 'scheduled', 'paid tips', 'paid hours', 'not paid' ],
    columns: [
      
      {
        field: 'delete',
        sortable: false,
        width: '48px',
      },
      {
        label: 'Date',
        field: 'date',
        width: '88px',
      },
      {
        label: 'Day',
        field: 'day',
        width: '88px',
      },
      // {
      //   label: 'Pay Status',
      //   field: 'paystatus',
      //   width: '152px',
      //   sortable: false,
      // },
      // {
      //   label: 'Event Status',
      //   field: 'status',
      //   sortable: false,
      // },
      // {
      //   field: 'note',
      //   sortable: false,
      // },
      {
        label: 'First',
        field: 'firstName',
        width: '100px',
      },
      {
        label: 'Last',
        field: 'lastName',
        width: '100px',
      },
      {
        label: 'Link',
        field: 'link',
        sortable: false,
      },
      {
        label: 'Shift',
        field: 'position',
        sortable: false,
      },
      {
        label: 'Time In',
        field: 'inTime',
        width: '100px',
        sortable: false,
      },
      {
        label: 'Time Out',
        field: 'outTime',
        width: '100px',
        sortable: false,
      },
      
      // {
      //   label: 'Total Hours',
      //   field: 'totalHours',
      //   width: '88px',
      // },
      // {
      //   label: 'Break Time',
      //   field: 'breakTime',
      //   width: '88px',
      //   sortable: false,
      // },
      // {
      //   label: 'Reg Hours',
      //   field: 'regHours',
      //   width: '88px',
      // },
      // {
      //   label: 'Min Hours',
      //   field: 'minHours',
      //   width: '88px',
      //   sortable: false,
      // },
      {
        label: 'Rate',
        field: 'regRate',
        width: '68px',
        sortable: false,
      },
      {
        label: 'Submit',
        field: 'submit',
        width: '78px',
        sortable: false,
      },

      // {
      //   label: 'OT',
      //   field: 'otHours',
      //   width: '88px',
      //   sortable: false,
      // },
      // {
      //   label: '2OT',
      //   field: 'ot2Hours',
      //   width: '88px',
      //   sortable: false,
      // },
      // {
      //   label: 'MBP Penalty',
      //   field: 'mbp',
      //   width: '88px',
      //   sortable: false,
      // },
      // {
      //   label: 'Tips',
      //   field: 'tips',
      //   width: '88px',
      // },
      // {
      //   label: 'Bonus',
      //   field: 'dayRate',
      //   width: '88px',
      //   sortable: false,
      // },
      // {
      //   label: 'Pay Total',
      //   field: 'payTotal',
      //   width: '100px',
      //   sortable: false,
      // },
      // {
      //   label: 'Onboarded',
      //   field: 'onboardStatus',
      //   sortable: false,
      // },
      // {
      //   label: 'Send Payment',
      //   field: 'save',
      //   width: '160px',
      //   sortable: false,
      // },
    ],
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile']),
  },
  components: {
    TimesheetNote
  },
  methods: {
    async updateAssignment(item) {
      await fb.assignmentsCollection.doc(item.id).update(item)
    },
    async removeIn(item) {
    await this.$store.dispatch('deleteInTimeAssignment', item);
    },
    async removeOut(item) {
      await this.$store.dispatch('deleteOutTimeAssignment', item);
    },
    showEditIn(row) {
      row.showEditIn = true
    },
    showEditOut(row) {
      row.showEditOut = true
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    formatAMPM(date) {
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }

      return date;
    },
    onUpdatePay(row) {
      this.$store.dispatch('updateTimesheetPay', {
        id: row.id,
        paystatus: row.paystatus
      })
    },
    onUpdateStatus(row) {
      this.$store.dispatch('updateTimesheetStatus', {
        id: row.id,
        status: row.status
      })
    },
    showNote(r) {
      this.activeItem = r
    },
    closeNote(r) {
      this.activeItem = null
    },
    async deleteEntry(row) {
      try {
        // Update Firestore document
        await fb.assignmentsCollection.doc(row.id).update({
          sendPlacementNotifications: false,
          deleting: true
        });

        // Dispatch Vuex action to delete the timesheet entry
        const eventId = this.eventAssignments.length > 0 ? this.eventAssignments[0].eventId : null;
        await this.$store.dispatch("deleteTimesheetEntry", {
          id: row.id,
          eventId: eventId
        });
      } catch (error) {
        console.error("Error deleting entry:", error);
        // Handle the error as needed
      }
    },

    removeEntry(row) {
      fb.assignmentsCollection.doc(row.id).update({
        hidden:true
      })
    },
    showEntry(row) {
      fb.assignmentsCollection.doc(row.id).update({
        hidden:false
      })
    },
    // async contCheck(props) {
    //   props.row.checking = true
    //   await this.$store.dispatch('refreshContractorByAssignment', props.row)
    // },
    // async empCheck(props) {
    //   props.row.checking = true
    //   await this.$store.dispatch('refreshEmployeeByAssignment', props.row)
    // },
    async onEditPosition(row) {
      console.log('updating position');
      if (!row.position) {
        return; // Exit early if position is not defined
      }

      const { id, position } = row;
      const updateData = {
        regRate: position.rate || null,
        position: position.title || null,
        totalUpdating: true
      };

      try {
        await fb.assignmentsCollection.doc(id).update(updateData);
        // Optionally, you can commit the changes to Vuex state here if needed.
      } catch (error) {
        console.error("Error updating position:", error);
        // Handle the error as needed
      }
    },

    onEditRegRate(row) {
      console.log('updating reg rate')
      if (row.regRate) {
        fb.assignmentsCollection.doc(row.id).update({
          regRate: row.regRate,
          totalUpdating: true
        })
      }

    },
    onEditInOut(row) {
      console.log('updating both in out')
      console.log(row)
      if (row.inTime && row.outTime) {
        fb.assignmentsCollection.doc(row.id).update({
          inTime: row.inTime,
          outTime: row.outTime,
          totalUpdating: true
        })
      } else {
      }
    },
    onEditTotalHours(row) {
      console.log('editing total hours')
      if (row.totalHours) {
        fb.assignmentsCollection.doc(row.id).update({
          totalHours: row.totalHours || 0
        })
        this.onEditRegHours(row)
      }
    },
    // onEditRegHours(row) {
    //   console.log('updating reg hours')
    //   if (row.breakTime) {
    //     let calcHours = (row.totalHours - row.breakTime)
    //     console.log(calcHours)
    //     fb.assignmentsCollection.doc(row.id).update({
    //       regHours: calcHours,
    //       totalUpdating: true
    //     })
    //     row.regHours = calcHours

    //   } else {
    //     fb.assignmentsCollection.doc(row.id).update({
    //       regHours: row.totalHours,
    //       totalUpdating: true
    //     })
    //     row.regHours = row.totalHours

    //   }
    // },
    onManEditRegHours(row) {
      if (row.minHours) {
        fb.assignmentsCollection.doc(row.id).update({
          regHours: row.regHours,
          totalUpdating: true
        })
      }
    },
    onEditMinHours(row) {
      console.log('updating min hours')
      if (row.minHours) {
        fb.assignmentsCollection.doc(row.id).update({
          minHours: row.minHours,
          totalUpdating: true
        })
      }
    },
    onEditotHours(row) {
      console.log('updating ot hours')
      if (row.otHours) {
        fb.assignmentsCollection.doc(row.id).update({
          otHours: row.otHours,
          totalUpdating: true
        })

      } else {
        null
      }
    },
    onEditot2Hours(row) {
      console.log('updating 2ot rate')
      if (row.ot2Hours) {
      fb.assignmentsCollection.doc(row.id).update({
        ot2Hours: row.ot2Hours,
        totalUpdating: true
      })
      } else {
        null
      }
    },
    onEditDayRate(row) {
      console.log('updating day rate')
      if (row.dayRate) {
      fb.assignmentsCollection.doc(row.id).update({
        dayRate: row.dayRate,
        totalUpdating: true
      })
      } else {
        null
      }
    },
    onEditBreakTime(row) {
      console.log('updating break')
      if (row.breakTime) {
      fb.assignmentsCollection.doc(row.id).update({
        breakTime: row.breakTime,
        totalUpdating: true
      })
      // this.onEditRegHours(row)
      } else {
        null
      }
    },
    onEditMBP(row) {
      console.log('updating mbp')
      if (row.mbp) {
      fb.assignmentsCollection.doc(row.id).update({
        mbp: row.mbp,
        totalUpdating: true
      })
      } else {
        null
      }
    },
    onEditTips(row) {
      console.log('updating tips')
      if (row.tips) {
        fb.assignmentsCollection.doc(row.id).update({
          tips: row.tips,
          totalUpdating: true
        })
      } else {
        null
      }
    },
     onUpdateTotal(row) {
      row.totalUpdating = true
      this.$store.dispatch('updateAssignmentTotal', row)
    },
    exportReport() {
      this.performingRequest2 = true
      const exportHeaders = [
        "Date",
        "First Name",
        "Last Name",
        "Position",
        "Hourly Rate",
        "Clock-In",
        "Clock-Out",
        "Total Hours",
        "Break",
        "Reg Hours",
        "Minimum Hours",
        "Billable Hours",
        "Overtime",
        "2x Overtime",
        "Break Penalty",
        "CC Tips"
      ];
      const exportItems = [];
      for (var key in this.batch) {
        if (!this.batch[key].hidden) {


          let billHours
          if (this.batch[key].minHours > this.batch[key].regHours) {
            billHours = this.batch[key].minHours
          } else {
            billHours = this.batch[key].regHours
          } 
          exportItems.push([
            this.batch[key].date,
            this.batch[key].firstName,
            this.batch[key].lastName,
            this.batch[key].position,
            this.batch[key].regRate,
            this.batch[key].inTime,
            this.batch[key].outTime,
            this.batch[key].totalHours,
            this.batch[key].breakTime,
            this.batch[key].regHours,
            this.batch[key].minHours,
            billHours,
            this.batch[key].otHours,
            this.batch[key].ot2Hours,
            this.batch[key].mbp,
            this.batch[key].tips
          ]);
        }
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
      setTimeout(() => {
        this.performingRequest2 = false
      }, 2000)
    },
    async markPaid() {
      this.performingRequest3 = true
      await this.batch.forEach(item => {
        fb.assignmentsCollection.doc(item.id).update({
          paystatus: 'paid'
        })
      })
      this.performingRequest3 = false
    },
    payBatch() {
      this.performingRequest = true
      let payArray = []
      this.batch.forEach(item => {
        console.log(item)

        let reg
        let ot
        let ot2
        
        if (item.regHours) {
          reg = (item.regHours + ' Reg Hours' + ' / ')
        } else {
          reg = ''
        }

        if (item.otHours) {
          ot = (item.otHours + ' Overtime' + ' / ')
        } else {
          ot = ''
        }

        if (item.ot2Hours) {
          ot2 = (item.ot2Hours + ' 2 Overtime' + ' / ')
        } else {
          ot2 = ''
        }

        let payNote = item.day + ' / ' + reg + ot + ot2 + ' ' + 'Base Rate: ' + item.regRate
        let total = {
          amount: item.payTotal
        }
        let newItem = {
          amount: total,
          taxCalcMethod: 'OPTIMIZED',
          externalWorkerId: item.userId,
          note: payNote,
          type: item.eventInfo.workerType || null,
          title: item.eventName || 'Jump Payment',
          assignmentId: item.id
        }
        payArray.push(newItem)
      })

      console.log(payArray) 
      let workerType = payArray[0].type
      let payTitle = payArray[0].title
      this.$store.dispatch('createBatchPayment', {
        type: workerType,
        users: payArray,
        title: payTitle,
      })
      // .then(response => {
      //     console.log(response)
      // })
      payArray.forEach(item => {
        console.log(item)
        fb.assignmentsCollection.doc(item.assignmentId).update({
          paystatus: 'paid',
        })
      })
      setTimeout(() => {
        this.batch = []
        this.performingRequest = false
      }, 2000)

    },
    newPayable(row) {
      console.log(row)
      let reg
        let ot
        let ot2
        
        if (row.regHours) {
          reg = (row.regHours + ' Reg Hours' + ' / ')
        } else {
          reg = ''
        }

        if (row.otHours) {
          ot = (row.otHours + ' Overtime' + ' / ')
        } else {
          ot = ''
        }

        if (row.ot2Hours) {
          ot2 = (row.ot2Hours + ' 2 Overtime' + ' / ')
        } else {
          ot2 = ''
        }

        let payNote = row.day + ' / ' + reg + ot + ot2 + ' ' + 'Base Rate: ' + row.regRate
      // let payNote = row.name + ' / ' + row.day
      this.$store.dispatch('createNewPayable', {
        amount: row.payTotal,
        workerId: row.realId || row.userId,
        note: payNote,
        assignmentId: row.id,
        title: row.eventName,
        type: 'Contractors',
      })
      .then(result => {
        if (result && result.data) {
          console.log(result.data)
        }
      })
    },
    bulkPayable() {

      this.performingRequest = true
      let payArray = []
      this.batch.forEach(item => {

        let reg
        let ot
        let ot2
        
        if (item.regHours) {
          reg = (item.regHours + ' Reg Hours' + ' / ')
        } else {
          reg = ''
        }

        if (item.otHours) {
          ot = (item.otHours + ' Overtime' + ' / ')
        } else {
          ot = ''
        }

        if (item.ot2Hours) {
          ot2 = (item.ot2Hours + ' 2 Overtime' + ' / ')
        } else {
          ot2 = ''
        }

        let total = {
          amount: item.payTotal
        }


        let payNote = item.name + ' / ' + reg + ot + ot2 + ' ' + 'Base Rate: ' + item.regRate + ' / ' + item.day
        // let payNote = row.name + ' / ' + row.day

        let newItem = {
          amount: total,
          taxCalcMethod: 'OPTIMIZED',
          externalWorkerId: item.userId,
          note: payNote,
          type: item.eventInfo.workerType || null,
          title: item.eventName || 'Jump Payment',
          message: item.id
        }

        payArray.push(newItem)

        let workerType = payArray[0].type
        let payTitle = payArray[0].title

        this.$store.dispatch('createNewPayable', {
          amount: item.payTotal,
          workerId: item.realId || item.userId,
          note: payNote,
          assignmentId: item.id,
          title: item.eventName,
          type: workerType,
        })
        .then(result => {
          if (result && result.data) {
            console.log(result.data)
          }
        })
        setTimeout(() => {
          this.performingRequest = false
        }, 2000)
      })
    },
    cancelEmployeePayment(row) {
      this.$store.dispatch("deletePayable", row) 
    },

    sendEmployeePayment(row) {
      // this.isProcessing = true
      console.log(row)
      let reg
        let ot
        let ot2
        
        if (row.regHours) {
          reg = (row.regHours + ' Reg Hours' + ' / ')
        } else {
          reg = ''
        }

        if (row.otHours) {
          ot = (row.otHours + ' Overtime' + ' / ')
        } else {
          ot = ''
        }

        if (row.ot2Hours) {
          ot2 = (row.ot2Hours + ' 2 Overtime' + ' / ')
        } else {
          ot2 = ''
        }

        let payNote = row.day + ' / ' + reg + ot + ot2 + ' ' + 'Base Rate: ' + row.regRate
      console.log(payNote)
      this.$store.dispatch('createNewPayable', {
        amount: row.payTotal,
        workerId: row.realId || row.userId,
        note: payNote,
        assignmentId: row.id,
        title: row.eventName,
        type: 'Employees',
      })
      .then(result => {
        if (result && result.data) {
          console.log(result.data)
          // this.isProcessing = false
        }
      })
    },
    sendPayment(row) {
      this.isProcessing = true
      console.log(row)
      let reg
        let ot
        let ot2
        
        if (row.regHours) {
          reg = (row.regHours + ' Reg Hours' + ' / ')
        } else {
          reg = ''
        }

        if (row.otHours) {
          ot = (row.otHours + ' Overtime' + ' / ')
        } else {
          ot = ''
        }

        if (row.ot2Hours) {
          ot2 = (row.ot2Hours + ' 2 Overtime' + ' / ')
        } else {
          ot2 = ''
        }

        let payNote = row.day + ' / ' + reg + ot + ot2 + ' ' + 'Base Rate: ' + row.regRate
      this.$store.dispatch('createUserPayment', {
        amount: row.payTotal,
        id: row.realId || row.userId,
        note: payNote,
        assignmentId: row.id,
        title: row.eventName
      })
      .then(result => {
        if (result && result.data) {
          console.log(result.data)
          this.isProcessing = false
        }
      })
    },
    // async entTimesheetToEveree(row) {
    //   const retrieveChecks = firebase.functions().httpsCallable('retrieveChecks')
    //   fb.assignmentsCollection.doc(row.id).update({        isTimesheetSubmitted: true
    //   })
    // },
    // const deleteTimesheetFromEveree = firebase.functions().httpsCallable('deleteTimesheetFromEveree')
    //     await deleteTimesheetFromEveree({
    //     id: row.workedShiftId
    //   })
    //   .then(result => {
    //     console.log(result)
    //     alert(result)
    //   })
    //   this.employeeTimesheetHistory.splice(row);
    //   this.performingRequest = false
    async sendTimesheetToEveree(row) {
      row.isTimesheetSubmitting = true
      console.log('sendTimesheetToEveree')
      const sendTimesheetToEveree = firebase.functions().httpsCallable('sendTimesheetToEveree')

      await sendTimesheetToEveree(row)
      .then(result => {
        if (result && result.data && result.data.error) {
          console.log(result)
          alert(result.data.error)
        }
        
      })
      row.isTimesheetSubmitting = false
    },
  },
  beforeDestroy () {
    this.performingRequest = null
    delete this.performingRequest
    this.performingRequest2 = null
    delete this.performingRequest2
    this.performingRequest3 = null
    delete this.performingRequest3
    this.columns = null
    delete this.columns
    this.isProcessing = null
    delete this.isProcessing
    this.activeItem = null
    delete this.activeItem
    this.activeDay = null
    delete this.activeDay
    this.batch = null
    delete this.batch
    this.statuses = null
    delete this.statuses
    this.paystatuses = null
    delete this.paystatuses
    delete this.onSheetEdit
    delete this.onSheetEditable
    delete this.newEmployeePayable
    delete this.sendPayment
    delete this.payBatch
    delete this.onUpdateTotal
    delete this.onEditTips
    delete this.onEditMBP
    delete this.onEditBreakTime
    delete this.onEditDayRate
    delete this.onEditot2Hours
    delete this.onEditotHours
    delete this.onEditMinHours
    delete this.onManEditRegHours
    // delete this.onEditRegHours
    delete this.onEditTotalHours
    delete this.onEditIn
    delete this.onEditOut
    delete this.onEditRegRate
    delete this.onEditPosition
    delete this.showEntry
    delete this.removeEntry
    delete this.showNote
    delete this.closeNote
    delete this.onUpdateStatus
    delete this.onUpdatePay
    delete this.formatAMPM
    // delete this.formatDate
    delete this.filteredInfo
    // delete this.filteredEmployee
    delete this.selectionChanged
    delete this.showEditIn
    delete this.showEditOut
    delete this.removeIn
    delete this.removeOut
  },
  destroyed() {
    console.log(this)
  }
}
</script>