<template>
  <div>
    <Loader v-if="performingRequest" />
    <Loader v-if="showAllUser && (!eventGroupUsers || (eventGroupUsers && eventGroupUsers.length == 0))" />
    <Loader v-if="showAllOpen && (!eventAssignments || (eventAssignments && eventAssignments.length == 0))" />

    <div v-if="eventInfo && eventInfo.id && (!eventInfo.shiftType || (eventInfo.shiftType != 'Permanent' && eventInfo.shiftType != 'Job'))">

      <div class="whiteBack mb-3" style="width:50%; min-width: 20rem;" v-if="assignmentDays && assignmentDays.length > 0">
        <h5 class="mb-2" v-if="!showAllOpen">Choose Day:</h5>

        <v-select
          v-if="!showAllOpen"
          label="title" 
          :options="assignmentDays"
          clearable
          :id="`pickDay` + eventInfo.id"
          v-model="activeDay"
          @input="updateDay()"
          >
        </v-select>
        <div class="flex justify-space-between">
          <button class="btn btn__outlined btn__small mt-4" @click="showAllSheets()" v-if="!showAllOpen">Show All</button>
          <button class="btn btn__outlined btn__small mt-4" @click="hideAllSheets()" v-if="showAllOpen">Hide All</button>

          <button v-if="!isShowAddRows" class="btn btn__small btn__outlined mr-3 mt-4" @click="showAddRows()">Add Rows <i class="fa-regular fa-plus ml-2"></i></button>
          <button v-if="isShowAddRows" class="btn btn__small btn__dark mr-3 mt-4" @click="hideAddRows()">Hide Adding Rows <i class="fa-regular fa-plus ml-2"></i></button>
        </div>
      </div>
    </div>
    <div v-if="eventInfo && eventInfo.id && ((eventInfo.shiftType && (eventInfo.shiftType == 'Permanent' || eventInfo.shiftType == 'Job')) || showAllOpen)">
      <div class="flex flex-wrap justify-space-between" style="width:100%;">
        <span class="mb-3">

          <button class="btn btn__small mr-3 btn__outlined" @click="showAllSheets()" v-if="!showAllOpen">Show All Open Timesheets</button>

          <button class="btn btn__small mr-3 btn__dark" v-if="showAllOpen">Show All Open Timesheets</i></button>

          <button class="btn btn__small mr-3 btn__outlined" @click="showUserFilter()" v-if="!showAllUser && !filteredUser">Show Timesheets By User<i class="fa-solid fa-plus  ml-2"></i></button>
          <button class="btn btn__small mr-3 btn__dark" @click="hideUserFilter()" v-if="showAllUser && !filteredUser">Hide Timesheets By User <i class="fa-solid fa-minus ml-2"></i></button>

          <button class="btn btn__small mr-3 btn__dark" @click="hideUserFilter()" v-if="filteredUser && showAllUser">Hide Timesheets By User <i class="fa-solid fa-minus ml-2"></i></button>

          <button class="btn btn__small mr-3 btn__dark" @click="showUserFilter()" v-if="filteredUser && !showAllUser">Show Timesheets By User <i class="fa-solid fa-plus ml-2"></i></button>

          <span v-if="((userAssignmentsByDateFilter && userAssignmentsByDateFilter.length >= 1) || (assignmentsByDateFilter && assignmentsByDateFilter.length >= 1))">
            <transition name="fade">

              <button v-if="(!isShowDateFilter && (!assignmentsByDate || assignmentsByDate.length == 0))" class="btn btn__small mr-3 btn__outlined" @click="showDateFilter()">Date Filter <i class="fa-regular fa-sliders ml-2"></i></button>

              <button v-if="(!isShowDateFilter && (assignmentsByDate && assignmentsByDate.length >= 1))" class="btn btn__small mr-3 btn__primary" @click="showDateFilter()">Date Filter <i class="fa-regular fa-sliders ml-2"></i></button>

              <button v-if="(isShowDateFilter && (assignmentsByDate && assignmentsByDate.length >= 1))" class="btn btn__small mr-3 btn__primary" @click="hideDateFilter()">Date Filter <i class="fa-regular fa-sliders ml-2"></i></button>

              <button v-if="(isShowDateFilter && (!assignmentsByDate || assignmentsByDate.length == 0))" class="btn btn__small mr-3 btn__dark" @click="hideDateFilter()">Date Filter <i class="fa-regular fa-sliders ml-2"></i></button>

            </transition>
          </span>

          <button v-if="!isShowAddRows" class="btn btn__small btn__outlined mr-3" @click="showAddRows()">Add Rows <i class="fa-regular fa-plus ml-2"></i></button>
          <button v-if="isShowAddRows" class="btn btn__small btn__dark mr-3" @click="hideAddRows()">Hide Adding Rows <i class="fa-regular fa-plus ml-2"></i></button>

          

        </span>
        <!-- <span class="mb-3">
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isVisible, 'btn__outlined': !isVisible }" @click="showVisiblePerm()">Visible</button>
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isPaid, 'btn__outlined': !isPaid }" @click="showPaidPerm()">Paid</button>

         
        </span> -->

      </div>
    </div>

    <transition name="fadeStop">
      <div v-if="isShowDateFilter">
        <!-- <h5>Filter Dates:</h5> -->
        <div class="flex flex-row flex-wrap mt-3 mb-3">
          <div class="whiteBack">
            <label for="start">Start Date</label>
            <input type="date" v-model="startDate" />
          </div>
          <div class="whiteBack ml-5">
            <label for="end">End Date</label>
            <input type="date" v-model="endDate" />
          </div>
          <div class="ml-5 mb-3" v-if="endDate && startDate">
            
            <div>
            <button class="btn btn__outlined btn__small" @click="clearDates()">Clear Dates</button>
            </div>
          </div>
          
        </div>
      </div>
    </transition>

    <transition name="fadeStop">
      <div v-if="showAllUser">
        
        <div class="flex flex-row flex-wrap mt-3 mb-3">
          <div class="whiteBack" style="width:25%; min-width: 28rem;" >
            <label for="start">Select Worker:</label>
            <div class="flex">
              <div style="width:100%;">
              <v-select
                v-if="eventGroupUsers && eventGroupUsers.length >= 1"
                label="firstName"
                :options="eventGroupUsers"
                v-model="filteredUser"
                @input="updateActiveUser"
                :clearable="false"
                >
                <template #selected-option="{ firstName, lastName }">
                  <div style="display: flex; align-items: baseline">
                    <div>{{ firstName }} {{lastName}}</div>
                  </div>
                </template>
                <template #option="option">
                  {{option.firstName}} {{option.lastName}} // {{option.position}}
                </template>
              </v-select>
            </div>
            <button v-if="filteredUser" class="btn btn__small btn__outlined ml-3" @click="clearUser()">Clear</button>
            </div>
          </div>
      </div>
      </div>
    </transition>

    <transition name="fadeStop">
      <div v-if="isShowAddRows">
        <div class="flex flex-row flex-wrap">
          <div class="flex mt-3">
            <button class="chipDark mr-2 mb-2" v-for="(range, index) in selectedRange" :key="index" @click="removeRange(index)">{{ range.start }} <i class="fa-regular fa-xmark ml-2"></i></button>
          </div>
        </div>
        <div class="flex flex-row flex-wrap">
          <div class="whiteBack mb-3" style="width:20%; min-width: 18rem;">
            <div class="mb-3 flex flex-column">
              <label for="pickDate">Select Dates:</label>
              <div class="date-range-picker">
                <input type="date" v-model="startDate">
                <div> to </div>
                <input type="date" v-model="endDate">
                <div class="mt-4">
                <button class="btn btn__small btn__primary" @click="addRange">Add Dates</button>
                </div>
              </div>



              <!-- <input type="date" v-model="newShiftDate" id="pickDate" @input="onPickDate()" /> -->
            </div>
          </div>

          <div class="whiteBack mb-3 ml-3" style="width:20%; min-width: 18rem;">
            <!-- <div class="flex flex-row flex-wrap">
              <div class="flex mt-3">
                <button class="chipDark mr-2 mb-2" v-for="(user, index) in newRow.users" :key="index" @click="removeUser(index)">{{ user.firstName }} {{ user.lastName }}<i class="fa-regular fa-xmark ml-2"></i></button>
              </div>
            </div> -->
            <div v-if="eventInfo && eventInfo.id && (!eventInfo.shiftType || (eventInfo.shiftType != 'Permanent' && eventInfo.shiftType != 'Job'))" class="flex justify-space-between align-center">

              <ais-instant-search :search-client="searchClient" index-name="a_users" style="width:100%; max-width: 480px;">
                <ais-search-box placeholder="Add User..." />
                <ais-state-results>
                  <template slot-scope="{ state: { query } }">
                    <ais-hits v-show="query.length > 0">
                      <template v-slot:item="{ item }">
                        <div>
                          <button @click="addUser(item)" class="btn btn__icon btn__flat mr-4">
                            </span>
                            <i class="fas fa-plus" style="color:blue;" v-if="!performingRequest2"></i>
                            <i class="fa fa-spinner fa-spin" style="color:blue;" v-if="performingRequest2"></i>
                          </button>
                          <p style="display: inline;">{{ item.firstName }} {{ item.lastName }} | <span v-if="item.address && item.address">{{item.address.city}} | </span>{{item.email}} | {{item.phone}}</p>
                        </div>
                      </template>
                    </ais-hits>
                  </template>
                </ais-state-results>
              </ais-instant-search>
            </div>
            <div v-if="eventInfo && eventInfo.id && (eventInfo.shiftType && (eventInfo.shiftType == 'Permanent' || eventInfo.shiftType == 'Job'))">
              <div class="mb-3" v-if="(eventGroupUsers && eventGroupUsers.length >= 1)">
                <h5 >Worker:</h5>
                <v-select
                  :options="eventGroupUsers"
                  clearable
                  multiple
                  v-model="newRow.users"
                  label="firstName"
                  >
                  <template #selected-option="{ firstName, lastName, position }">
                  <div style="display: flex; align-items: baseline">
                    <div>{{ firstName }} {{lastName}} // {{position}}</div>
                  </div>
                </template>
                  <template #option="option">
                  {{option.firstName}} {{option.lastName}} // {{option.position}}
                </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="whiteBack mb-3 ml-3" style="width:32%; min-width: 28rem;">
            <div class="mb-3" v-if="(permShifts && permShifts.length >= 1)">
              <h5>Shift:</h5>
              <v-select

                label="name"
                :options="permShifts"
                clearable
                v-model="newRow.shift"
                >
                <template #option="option">
                  {{option.name}} - {{option.day}}
                </template>
              </v-select>
            </div>
            <!-- <div class="mb-3" v-if="(!permShifts || permShifts.length == 0) && (eventShifts && eventShifts.length >= 1)">
              <label>Shift:</label>
              <v-select
                label="name"
                :options="eventShifts"
                clearable
                v-model="newRow.shift"
                >
                <template #option="option">
                  {{option.name}} - {{option.day}}
                </template>
              </v-select>
            </div> -->
            <div class="mb-3">
              <label>Position:</label>
              <v-select
                label="title"
                :options="eventInfo.jobs"
                clearable
                v-model="newRow.job"
                >
               
              </v-select>
            </div>
          </div>
          <div class="mb-3 ml-3" v-if="newRow.job && newRow.users && newRow.users.length >= 1 && selectedRange && selectedRange.length >= 1">
            <button class="btn btn__primary btn__small" @click="buildRows()">Add Rows</button>
          </div>
        </div>
      </div>
    </transition>


    <div v-if="eventInfo && eventInfo.id && (eventInfo.shiftType && (eventInfo.shiftType == 'Permanent' || eventInfo.shiftType == 'Job'))">

      <div class="dashboard__container--body pt-3" v-if="((assignmentsByDateFilter && assignmentsByDateFilter.length >= 1) || (userAssignmentsByDateFilter && userAssignmentsByDateFilter.length >= 1))">

        <PayrollTable v-if="(showAllOpen && (assignmentsByDateFilter || assignmentsByDateFilter.length > 0) && !isHidden)" :eventAssignments="assignmentsByDateFilter" :type="eventInfo.workerType" :eventInfo="eventInfo" />


        <PayrollTable v-if="(showAllUser && (userAssignmentsByDateFilter && userAssignmentsByDateFilter.length >= 1) && !isHidden & !isPaid)" :eventAssignments="userAssignmentsByDateFilter" :type="eventInfo.workerType" :eventInfo="eventInfo"/>

        <!-- <PayrollTable v-if="isHidden && allHidden && allHidden.length >= 1" :eventInfo="eventInfo" :type="eventInfo.workerType" :eventAssignments="allHidden" :hidden="true" /> -->

        <PayrollTable v-if="isPaid" :eventInfo="eventInfo" :eventAssignments="allPaid" :type="eventInfo.workerType" />

      </div>

    </div>






    <div class="pt-3" v-if="eventInfo && eventInfo.id && (!eventInfo.shiftType || (eventInfo.shiftType != 'Permanent' && eventInfo.shiftType != 'Job'))">
      <div class="dashboard__container--body"  v-if="showAllOpen">

        <!-- <PayrollTable v-if="(filteredAssignments && filteredAssignments.length >= 1) && !isHidden" :eventAssignments="filteredAssignments" :type="eventInfo.workerType" /> -->

        <PayrollTable v-if="isVisible && (!visibleAssignments || visibleAssignments.length == 0)" :eventInfo="eventInfo" :eventAssignments="visibleAssignments" :type="eventInfo.workerType" />

        <PayrollTableAlt v-if="isHidden && (!hiddenAssignments || hiddenAssignments.length == 0)" :eventInfo="eventInfo" :eventAssignments="hiddenAssignments" :hidden="true" :type="eventInfo.workerType" />

        <PayrollTable v-if="isPaid && (!paidAssignments || paidAssignments.length == 0)" :eventInfo="eventInfo" :eventAssignments="paidAssignments" :hidden="false" :type="eventInfo.workerType" />

      </div>

      <div class="dashboard__container--body"  v-if="activeDay && !showAllOpen">

        <div class="text-right mb-3" style="width:100%;">
              
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isVisibleByDay, 'btn__outlined': !isVisibleByDay }" @click="showVisibleByDay()">Visible</button>
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isPaidByDay, 'btn__outlined': !isPaidByDay }" @click="showPaidByDay()">Paid</button>
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isHiddenByDay, 'btn__outlined': !isHiddenByDay }" @click="showHiddenByDay()">Hidden</button>
          <!-- <button class="btn btn__small btn__outlined mr-3 mb-3" @click.prevent="exportRegisterByDay()">Day Billing Export<i class="fas fa-external-link ml-3"></i></button> -->
        </div>

        <PayrollTable v-if="isVisibleByDay" :eventInfo="eventInfo" :eventAssignments="visibleAssignmentsByDay" :type="eventInfo.workerType" />

        <PayrollTableAlt :hidden="true" v-if="isHiddenByDay" :eventInfo="eventInfo" :eventAssignments="hiddenAssignmentsByDay" :type="eventInfo.workerType" />

        <PayrollTable :hidden="false" v-if="isPaidByDay" :eventInfo="eventInfo" :eventAssignments="paidAssignmentsByDay" :type="eventInfo.workerType" />

      </div>
    </div>
  </div>
</template>

<style scoped>
  .vgt-selection-info-row {
    height: 3.28rem;
  }

.date-range-picker {
  display: inline-block;
  position: relative;
}

.date-range-picker input[type="date"] {
  width: 150px;
}

.selected-dates {
  margin-top: 10px;
}

.selected-dates .selected-date {
  display: inline-block;
  background-color: #007BFF;
  color: #fff;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
}
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
// import ExportService from "@/services/ExportService"
// import TimesheetNote from '@/components/Timesheets/TimesheetNote.vue'
import PayrollTableSimple from '@/components/PayrollTableSimple.vue'
import PayrollTable from '@/components/PayrollTable.vue'
import PayrollTableAlt from '@/components/PayrollTableAlt.vue'  
import firebase from 'firebase/app';
// import * as moment from 'moment'
import algoliasearch from 'algoliasearch/lite';
// import * as moment from 'moment'
const fb = require('../../../firebaseConfig.js')

export default {
  props: ['eventInfo'],
  name: 'timeTimesheets',
  data: () => ({
    searchClient: algoliasearch(
      '0T1SIY6Y1V',
      'f03dc899fbdd294d6797791724cdb402',
    ),
    startDate: '',
      endDate: '',
      selectedRange: [],
    newShiftDate: "",
    shiftDates: [],
    isShowAddRows: false,
    isShowDateFilter: false,
    isShowShiftFilter: false,
    isShowUserFilter: false,
    performingRequest: false,
    performingRequest2: false,
    showAll: false,
    showAllOpen: false,
    showAllUser: false,
    isVisible: true,
    isHidden: false,
    isPaid: false,
    isVisibleByDay: true,
    isHiddenByDay: false,
    isPaidByDay: false,
    activeItem: null,
    activeDay: '',
    endDate: null,
    startDate: null,
    filteredShift: null,
    filteredUser: null,
    newRow: {
      shift: null,
      users: [],
    },
  }),
  mounted () {
    if (
      this.eventInfo &&
      this.eventInfo.id &&
      (!this.eventInfo.shiftType ||
        (this.eventInfo.shiftType !== 'Permanent' &&
          this.eventInfo.shiftType !== 'Job'))
    ) {
      this.$store.dispatch('getAssignmentDays', this.$route.params.id)
    } else {
      this.$store.dispatch('getEventGroupUsers', this.$route.params.id)
      
      
      
    }
    
  },
  computed: {
    ...mapState(['paidEventAssignments', 'eventAssignments', 'eventAssignmentsByDay', 'currentUser', 'userProfile', 'assignmentDays', 'eventGroupUsers', 'eventAssignmentsByUser']),
    // eventGroupUsers() {
    //   // return Array.from(new Set(this.eventGroupUsers));
    //   if (this.eventGroupUsers && this.eventGroupUsers.length > 0) {

    //   const uniqueGroup = this.eventGroupUsers.filter((value, index, self) =>
    //     index === self.findIndex((t) => (
    //       t.userId === value.userId
    //     ))
    //   )

    //   let uniqueArray = [...new Set(uniqueGroup)];

    //   // const filteredUnique = uniqueGroup.filter(item => {
    //   //   return !item.hidden
    //   // })
    //   return uniqueArray

    //   // return this.eventGroupUsers.filter((item,
    //   //   index) => this.eventGroupUsers.indexOf(item) === index);
    //   // // let uniqueArray = [...new Set(this.eventGroupUsers)];
    //   // // let uniqueArray = []
    //   // // this.eventGroupUsers.forEach(item => {
    //   // //   if (!uniqueArray.includes(item)) {
    //   // //     uniqueArray.push(item);
    //   // //   }
    //   // // })
    //   // return uniqueArray
    //   } else {
    //     return null
    //   }
    // },
    permShifts() {
      if (this.eventShifts && this.eventShifts.length > 1) {
        return this.eventShifts.filter(shift => {
          return (shift.type == 'Permanent' || shift.type == 'Job')
        })
      } else {
        return []
      }
    },
    allVisible() {
      if (this.eventAssignments && this.eventAssignments.length >= 1) {
       return this.eventAssignments.filter(item => {
          return (item && (!item.hidden) && (!item.paystatus || (item.paystatus != 'paid')))
        })
      } else {
        return 
      }
    },
    allHidden() {
      if (this.eventAssignments && this.eventAssignments.length > 0) {
        return this.eventAssignments.filter(item => {
          return (item.hidden)
        })
      } else {
        return []
      }
    },
    allPaid() {
      if (this.eventAssignments && this.eventAssignments.length > 0) {
        return this.eventAssignments.filter(item => {
          return ((!item.hidden || item.hidden != true) && (item.paystatus && item.paystatus === 'paid'))
        })
      } else {
        return []
      }
    },
    visibleAssignments() {
      if (this.showAllOpen && this.eventAssignments && this.eventAssignments.length > 0) {
        return this.eventAssignments.filter(item => {
          return ((!item.hidden || item.hidden != true) && (!item.paystatus || item.paystatus != 'paid'))
        })
      } else {
        return []
      }
    },
    hiddenAssignments() {
      if (this.showAllOpen && this.eventAssignments && this.eventAssignments.length > 0) {
        return this.eventAssignments.filter(item => {
          return (item.hidden)
        })
      } else {
        return []
      }
    },

    paidAssignments() {
      if (this.showAll && this.eventAssignments && this.eventAssignments.length > 0) {
        return this.eventAssignments.filter(item => {
          return (item.paystatus === 'paid')
        })
      } else {
        return []
      }
    },


    visibleAssignmentsByDay() {
      if (this.isVisibleByDay && this.activeDay && !this.showAll && this.eventAssignmentsByDay && this.eventAssignmentsByDay.length > 0) {
        return this.eventAssignmentsByDay.filter(item => {
          return ((!item.hidden || item.hidden != true) && (!item.paystatus || item.paystatus != 'paid') && item.day == this.activeDay)
        })
      } else {
        return []
      }
    },
    hiddenAssignmentsByDay() {
      if (this.isHiddenByDay && this.activeDay && !this.showAll && this.eventAssignmentsByDay && this.eventAssignmentsByDay.length > 0) {
        return this.eventAssignmentsByDay.filter(item => {
          return (item.hidden && item.day == this.activeDay)
        })
      } else {
        return []
      }
    },

    paidAssignmentsByDay() {
      if (this.isPaidByDay && this.activeDay && !this.showAll && this.eventAssignmentsByDay && this.eventAssignmentsByDay.length > 0) {
        return this.eventAssignmentsByDay.filter(item => {
          return (item.paystatus == 'paid' && item.day == this.activeDay)
        })
      } else {
        return []
      }
    },
    assignmentsByDate() {
      if (this.startDate && this.endDate && this.allVisible && this.allVisible.length > 0) {
        let startDate = new Date(this.startDate);
        let endDate = new Date(this.endDate);
      
        return this.allVisible.filter(item => {
          let eventDate = new Date(item.date)
          return ((eventDate >= startDate) && (eventDate <= endDate)) 
        })
      } else {
        return []
      }
    },
    assignmentsByDateFilter() {
      if (this.startDate && this.endDate && this.allVisible && this.allVisible.length > 0) {
        let startDate = new Date(this.startDate);
        let endDate = new Date(this.endDate);
      
        return this.allVisible.filter(item => {
          let eventDate = new Date(item.date)
          return ((eventDate >= startDate) && (eventDate <= endDate)) 
        })
      } else if (!this.startDate && !this.endDate && this.allVisible && this.allVisible.length > 0){
        return this.allVisible
      } else if (this.startDate && !this.endDate && this.allVisible && this.allVisible.length > 0){
        return this.allVisible
      } else if (!this.startDate && this.endDate && this.allVisible && this.allVisible.length > 0){
        return this.allVisible
      } else {
        return []
      }
    },
    userAssignmentsByDateFilter() {
      if (this.startDate && this.endDate && this.allVisible && this.eventAssignmentsByUser.length > 0) {
        let startDate = new Date(this.startDate);
        let endDate = new Date(this.endDate);
      
        return this.eventAssignmentsByUser.filter(item => {
          let eventDate = new Date(item.date)
          return ((eventDate >= startDate) && (eventDate <= endDate)) 
        })
      } else if (!this.startDate && !this.endDate && this.eventAssignmentsByUser && this.eventAssignmentsByUser.length > 0){
        return this.eventAssignmentsByUser
      } else if (this.startDate && !this.endDate && this.eventAssignmentsByUser && this.eventAssignmentsByUser.length > 0){
        return this.eventAssignmentsByUser
      } else if (!this.startDate && this.endDate && this.eventAssignmentsByUser && this.eventAssignmentsByUser.length > 0){
        return this.eventAssignmentsByUser
      } else {
        return []
      }
    },
    // assignmentsByShift() {
    //   if (this.filteredShift && this.allVisible && this.allVisible.length > 0) {
    //     return this.allVisible.filter(item => {
    //       return item.shiftId == this.filteredShift.id
    //     })
    //   } else {
    //     return []
    //   }
    // },
    // assignmentsByUser() {
    //   if (this.filteredUser) {
    //     return this.eventGroupUsers.filter(item => {
    //       return item.userId == this.filteredUser.userId
    //     })
    //   } else {
    //     return []
    //   }
    // },
    // filteredAssignments() {
    //   const usersArray = this.eventAssignmentsByUser || [];
    //   const datesArray = this.assignmentsByDate || [];

    //   // Start with all assignments
    //   let filteredArray = [...this.$store.state.allAssignments]; // Replace with your actual Vuex state property

    //   // Apply filters based on users and dates
    //   if (usersArray.length > 0) {
    //     filteredArray = filteredArray.filter((assignment) => usersArray.includes(assignment.user));
    //   }

    //   if (datesArray.length > 0) {
    //     filteredArray = filteredArray.filter((assignment) => datesArray.includes(assignment.date));
    //   }

    //   return filteredArray;
    // },



    // filteredAssignments() {
    //   const usersArray = this.eventAssignmentsByUser || [];
    //   const datesArray = this.allEmployeeAssignments || [];

    //   // Filter assignments based on users, shifts, and dates
    //   let filteredArray = [...usersArray,  ...datesArray];

    //   // If usersArray is not empty, filter by users
    //   if (usersArray.length > 0) {
    //     filteredArray = usersArray
    //   }

    //   // If datesArray is not empty, filter by dates
    //   if (datesArray.length > 0) {
    //     filteredArray = datesArray
    //   }

    //   // If datesArray and usersArray not empty, display the results that are in both arrays
    //   if (datesArray.length > 0 && usersArray.length > 0) {
    //     filteredArray = (usersArray.some(item => datesArray.includes(item)))
    //   }


    //   return filteredArray;
    // },

  },
  components: {
    Loader,
    PayrollTable,
    PayrollTableAlt,
    PayrollTableSimple
  },
  
  methods: {
    async submitDates() {
      this.performingRequest = true; 
      await this.$store.dispatch("getallEmployeeAssignmentsByDateAndEvent", {
        startDate: this.startDate,
        endDate: this.endDate,
        type: 'Employees',
        eventId: this.eventInfo.id
      })
      this.performingRequest = false
    },
    addUser(item) {
      this.performingRequest2 = true;
      console.log(item)
      item.userId = item.objectID
      let users = this.newRow.users
      users.push(item)
      setTimeout(() => {
        this.performingRequest2 = false;
        document
        .querySelectorAll('.ais-SearchBox-input')
        .forEach((e) => (e.value = ''))
        document.querySelectorAll('.ais-Hits-item').forEach((e) => e.remove())
      }, 250)
    },
    addRange() {
      if (this.startDate && this.endDate) {
        const start = new Date(this.startDate);
        const end = new Date(this.endDate);
        let currentDate = new Date(start);

        while (currentDate <= end) {
          this.selectedRange.push({
            start: new Date(currentDate).toISOString().split('T')[0],
            end: new Date(currentDate).toISOString().split('T')[0],
          });

          // Increment the current date
          currentDate.setDate(currentDate.getDate() + 1);
        }

        this.clearInputs();
      }
    },
    removeRange(index) {
      this.selectedRange.splice(index, 1);
    },
    removeUser(index) {
      this.newRow.users.splice(index, 1);
    },
    clearInputs() {
      this.startDate = '';
      this.endDate = '';
    },
    async testMessage() {
      var startSMSConversation = firebase.functions().httpsCallable('startSMSConversation');
      let phone = this.userProfile.phone
      await startSMSConversation({
        recipient: `+19252122908`,
        userId: this.userProfile.id,
      })
    },
    // async getDynamicUsers() {
    //   this.performingRequest = true
    //   // await this.$store.dispatch("clearStaticEventAssignments")
    //   await this.$store.dispatch("getEventAssignments", this.$route.params.id)
    //   this.performingRequest = false
    // },
    // async getUsers() {
    //   this.performingRequest = true
    //   // await this.$store.dispatch("clearEventAssignments")
    //   await this.$store.dispatch("getStaticEventAssignments", this.$route.params.id)
    //   this.performingRequest = false
    // },
    removeDay(index) {
      this.shiftDates.splice(index, 1);
    },
    onPickDate() {
      this.shiftDates.push(this.newShiftDate)
      this.newShiftDate = ''
    },

    // async lockJob(props, shift) {
    //   props.row.status = "spinning"
    //   var lockJob = firebase.functions().httpsCallable('lockJob');
    //   await lockJob({
    //     event: this.event,
    //     day: this.activeDay,
    //     row: props.row,
    //     shift: shift,
    //   })
    //   .then((result) => {
    //     props.row.status = "assigned"
    //   })
    // },

    async buildRows() {
    console.log(this.newRow);
    this.performingRequest = true;

     const selectedRangeStarts = this.selectedRange.map(range => range.start);

    // Create the matrix using map and flatMap
    const matrix = this.newRow.users.flatMap(user =>
      selectedRangeStarts.map(date => ({ user, date }))
    );

    console.log(matrix)

    await this.addRows(matrix);
    this.selectedRange = []
    this.startDate = ''
    this.endDate = ''
    return matrix;
  },
  async addRows(matrix) {

      const promises = matrix.map(async (item) => {
      const fullName = item.user.firstName + ' ' + item.user.lastName;
      // item.user.realId = item.userId;
      // item.user.fullName = fullName;

      const theUser = {
        userId: item.user.userId,
        email: item.user.email,
        phone: item.user.phone,
        firstName: item.user.firstName,
        lastName: item.user.lastName,
        fullName: fullName,
      }

      console.log(theUser)

      const lockNewRows = firebase.functions().httpsCallable('lockNewRows');
        await lockNewRows({
          event: this.eventInfo,
          day: item.date,
          row: theUser,
          job: this.newRow.job,
          sendPlacementNotifications: false,
        });
      });

      // Use Promise.all to execute all the lockJob calls in parallel
      await Promise.all(promises);


      // Reset state after all requests are complete
      this.newRow = {};
      this.newShiftDate = null;
      this.shiftDates = [];
      this.performingRequest = false;
    },
    showAllSheets() {
      this.performingRequest = true
      if (this.eventAssignments && this.eventAssignments.length == 0) {
       this.$store.dispatch("getEventAssignments", this.$route.params.id)
      }
      this.showAll = true
      this.showAllOpen = true
      this.showAllUser = false
      this.isShowDateFilter = false
      this.isShowAddRows = false
      this.activeDay = ''
      if (this.eventAssignmentsByUser && this.eventAssignmentsByUser.length > 0) {
        this.$store.dispatch('clearEventAssignmentsByUser')
      }
      
      this.performingRequest = false
      this.filteredUser = null
    },
    hideAllSheets() {
      this.performingRequest = true
      // await this.$store.dispatch('clearEventAssignments')
      this.showAll = false
      this.showAllOpen = false
      
      this.performingRequest = false
    },
    showUserFilter() {
      this.performingRequest = true
      // if (this.eventGroupUsers && this.eventGroupUsers.length == 0) {
      //   await this.$store.dispatch('getEventGroupUsers', this.$route.params.id)
      // }
      this.isShowDateFilter = false
      this.showAllOpen = false
      this.showAll = false
      this.showAllUser = true
      this.isShowAddRows = false
      this.isShowUserFilter = false
      this.performingRequest = false
    },
    hideUserFilter() {
      this.performingRequest = true
      this.isShowUserFilter = false
      this.showAllUser = false
      // this.$store.dispatch("clearEventAssignmentsByUser")
      this.performingRequest = false
    },
    clearUser() {
      this.filteredUser = null
      this.$store.dispatch("clearEventAssignmentsByUser")
    },
    async updateActiveUser() {
      this.performingRequest = true
      await this.$store.dispatch("clearEventAssignmentsByUser")
      if (this.filteredUser) {
        console.log(this.filteredUser)
        await this.$store.dispatch("getEventAssignmentsByUser", this.filteredUser.userId)
        this.performingRequest = false
      } else {
        this.performingRequest = false
      }
    },
    async updateDay() {
      this.performingRequest = true
      this.isShowDateFilter = false
      this.isShowUserFilter = false
      this.isShowAddRows = false
      if (this.activeDay) {
        await this.$store.dispatch("getEventAssignmentsByDay", {
          eventId: this.eventInfo.id,
          day: this.activeDay
        })
      } else {
        // this.$store.dispatch('clearEventAssignmentsByDay')
      }
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    },
    // showShiftFilter() {
    //   this.showAll = true
    //   this.isShowShiftFilter = true
    //   this.isShowDateFilter = false
    //   this.isShowUserFilter = false
    //   this.isShowAddRows = false
    //   this.isHidden = false
    //   this.isVisible = true
    // },
    // hideShiftFilter() {
    //   this.showAll = false
    //   this.isShowShiftFilter = false
    // },
    // clearShift() {
    //   this.filteredShift = null
    // },
    showDateFilter() {
      this.isShowDateFilter = true
      this.isShowAddRows = false
      this.isShowUserFilter = false
      this.showAllUser = false
      // this.showAllUser = false
      // this.showAll = false
      // this.showAllOpen = false
    },
    hideDateFilter() {
      this.isShowDateFilter = false
    },
    clearDates() {
      this.startDate = null
      this.endDate = null
    },
    showAddRows() {
      this.isShowAddRows = true
      this.isShowDateFilter = false
      this.isShowShiftFilter = false
      this.isHidden = false
      this.isVisible = true
    },
    hideAddRows() {
      this.isShowAddRows = false
    },
    showHiddenPerm() {
      this.isShowDateFilter = false
      this.isShowAddRows = false
      this.isHidden = true
      this.isVisible = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showHidden() {
      this.isShowDateFilter = false
      this.isShowAddRows = false
      this.isHidden = true
      this.isVisible = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showVisiblePerm() {
      this.isVisible = true
      this.isHidden = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showVisible() {
      this.isVisible = true
      this.isHidden = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showPaid() {
      this.isPaid = true
      this.isVisible = false
      this.isHidden = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showPaidPerm() {
      this.isPaid = true
      this.isVisible = false
      this.isHidden = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showHiddenByDay() {
      if (this.activeDay) {
        this.isHiddenByDay = true
        this.isVisibleByDay = false
        this.isPaidByDay = false
        this.isHidden = false
        this.isVisible = false
        this.isPaid = false
      }
    },
    showVisibleByDay() {
      if (this.activeDay) {
        this.isVisibleByDay = true
        this.isHiddenByDay = false
        this.isPaidByDay = false
        this.isHidden = false
        this.isVisible = false
        this.isPaid = false
      }
    },
    showPaidByDay() {
      if (this.activeDay) {
        this.isPaidByDay = true
        this.isVisibleByDay = false
        this.isHiddenByDay = false
        this.isHidden = false
        this.isVisible = false
        this.isPaid = false
      }
    },
  },
  beforeDestroy () {
    this.$store.dispatch("clearEventAssignments")
    // this.$store.dispatch("clearEventGroupUsers")
    this.$store.dispatch("clearAssignmentDays")
    this.performingRequest = null
    delete this.performingRequest
    this.isVisible = null
    delete this.isVisible
    this.isHidden = null
    delete this.isHidden
    this.isVisibleByDay = null
    delete this.isVisibleByDay
    this.isHiddenByDay = null
    delete this.isHiddenByDay
    this.isPaid = null
    delete this.isPaid
    this.isPaidByDay = null
    delete this.isPaidByDay
    this.activeItem = null
    delete this.activeItem
    this.activeDay = null
    delete this.activeDay
    delete this.setStartDay
    delete this.updateDay
    delete this.showHidden
    delete this.showVisible
    delete this.showPaid
    delete this.showHiddenByDay
    delete this.showVisibleByDay
    delete this.showPaidByDay
    // delete this.formatDate
    delete this.exportRegisterByDay
    delete this.exportRegister
    delete this.visibleAssignmentsByDay
    delete this.hiddenAssignmentsByDay
    delete this.paidAssignmentsByDay
    this.newShiftDate = null
    delete this.newShiftDate
    this.shiftDates = null
    delete this.shiftDates
    this.isShowAddRows = null
    delete this.isShowAddRows
    this.isShowDateFilter = null
    delete this.isShowDateFilter
    this.isShowShiftFilter = null
    delete this.isShowShiftFilter
    this.isShowUserFilter = null
    delete this.isShowUserFilter
    this.endDate = null
    delete this.endDate
    this.startDate = null
    delete this.startDate
    this.filteredShift = null
    delete this.filteredShift
    this.filteredUser = null
    delete this.filteredUser
    delete this.permShifts
    this.newRow = null
    delete this.newRow
    this.startDate = null
    this.endDate = null
    delete this.endDate
    delete this.startDate
    console.log(this)
  },
  errorCaptured(err, vm, info) {
    // Handle errors here
    console.error('Error Captured Hook:', err, vm, info);
  },
}
</script>